import React, {useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Constants } from '../../adapters/helpers/Constants'
import BreadCrumbSEO from '../SEOschema/BreadCrumbSEO'
import { getBreadcrumbsBySlug } from '../../adapters/helpers/getBreadcrumbsBySlug';


const BreadCrumbs = (props) => {
  const [hasBreadcrumb, setHasBreadcrumb] = useState(false);
  const [breadCrumbValue, setBreadCrumbValue] = useState([]);
    const breadCrumbData = props.extraAttributes.breadCrumbData

    useEffect(() => {
      let pathName = window?.location?.pathname;
    async function fetchData() {
      const breadCrumbsValue = await getBreadcrumbsBySlug(pathName)
        if(breadCrumbsValue && breadCrumbsValue.length > 1){
          setHasBreadcrumb(true)
          setBreadCrumbValue(breadCrumbsValue)
        }        
      }
      fetchData();
        const productSubNavEle = document.getElementById('ob-product-subnav-scroll')
        const headerEle = document.getElementsByClassName('zone-header')

        if (!productSubNavEle && headerEle?.[0]) {
            headerEle[0].style.position = 'fixed'
        }
    }, [])

    return (
        <React.Fragment>
            {breadCrumbData && breadCrumbData?.length > 0 && <BreadCrumbSEO breadcrumb={breadCrumbData}></BreadCrumbSEO>}
            {breadCrumbData && breadCrumbData?.length > 0 ? (
                <React.Fragment>
                    <nav className='breadcrumb-main' aria-label={Constants.breadCrumpLabel} id='breadcrumb-main'>
                        <ul className='breadcrumb' itemScope aria-label='Breadcrumbs'>
                            {breadCrumbData &&
                                breadCrumbData.map((val, key) => {
                                    return (
                                        <li key={key}>
                                            {val.fields.link ? (
                                                <a aria-label={`${val?.fields?.title}`} className='event_internal_link' href={val.fields.link} data-action-detail={val.fields.link}>
                                                    {val.fields.title.toUpperCase()}
                                                </a>
                                            ) : (
                                                <span className='activeMenu'>{val?.fields?.title?.toUpperCase()}</span>
                                            )}
                                        </li>
                                    )
                                })}
                        </ul>
                    </nav>
                </React.Fragment>
            ) :
            ( hasBreadcrumb && breadCrumbValue ? (
              <React.Fragment>
                <nav className="breadcrumb-main" aria-label={Constants.breadCrumpLabel} id="breadcrumb-main">
              <ul className="breadcrumb" itemScope aria-label="Breadcrumbs">
                {breadCrumbValue &&
                  breadCrumbValue.map((val, key) => {
                    return (
                      <li key={key}>
                        {val.linkUrl ? (
                          <a aria-label={`${Constants.breadCrumpLabel}-${val.pageTitle}`} href={val.linkUrl}>{val.pageTitle}</a>
                        ) : (
                          <span className="activeMenu">{val.pageTitle}</span>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </nav>
            <BreadCrumbSEO breadcrumb={breadCrumbValue} />
            </React.Fragment> ) : (
                <React.Fragment>
                    <nav className='breadcrumb-main' aria-label={Constants.breadCrumpLabel} id='breadcrumb-main'>
                        <ul className='breadcrumb' itemScope aria-label='Breadcrumbs'>
                            <li>
                                <span className='op-breadcrumb-loading'>{Constants.Loading}</span>
                            </li>
                        </ul>
                    </nav>
                </React.Fragment>
            )) 
            
            }
        </React.Fragment>
    )
}

BreadCrumbs.propTypes = {
    breadcrumbs: PropTypes.array,
}

export default BreadCrumbs
